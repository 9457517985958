var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.renderNow
    ? _c("div", { attrs: { id: "loading-wrapper" } }, [
        _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
        _c("div", { attrs: { id: "loading-content" } }),
      ])
    : _c(
        "v-container",
        [
          _c(
            "div",
            { staticClass: "mt-4 mb-6", staticStyle: { "font-size": "20px" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "black", icon: "", large: "" },
                  on: {
                    click: function ($event) {
                      _vm.renderNow = false
                      _vm.$router.push("/teachers/announcements")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              ),
              !_vm.$language
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.editing ? "Edit" : "Create") + " Announcement"
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      " নোটিশ " +
                        _vm._s(_vm.editing ? "এডিট" : "তৈরী") +
                        " করুন"
                    ),
                  ]),
            ],
            1
          ),
          _c("submission-alert-message", {
            ref: "banner",
            staticClass: "my-3",
            staticStyle: { "max-width": "70vw" },
            attrs: { message: _vm.bannerMessage },
            model: {
              value: _vm.showBannerCondition,
              callback: function ($$v) {
                _vm.showBannerCondition = $$v
              },
              expression: "showBannerCondition",
            },
          }),
          _c(
            "v-card",
            {
              staticClass: "create-announcement-card",
              attrs: { flat: "", outlined: "" },
            },
            [
              _c("div", { staticClass: "mt-4 ml-6" }, [
                _vm._v(
                  " " +
                    _vm._s(!_vm.$language ? "Course List" : "কোর্স লিস্ট") +
                    " "
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "ml-4 d-flex align-center",
                  staticStyle: { height: "40px" },
                },
                [
                  _c("v-slide-y-transition", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.validating &&
                              _vm.selectedBatches.length === 0,
                            expression:
                              "validating && selectedBatches.length === 0",
                          },
                        ],
                        staticClass: "decline--text",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$language
                              ? "নূন্যতম একটি ব্যাচ বেছে নিন"
                              : "Please select atleast one batch"
                          )
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pr-8",
                      staticStyle: {
                        "overflow-y": "auto",
                        "overflow-x": "hidden",
                        "max-height": "60vh",
                        "max-width": "80vw",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panels",
                        {
                          staticClass: "margin-announcement-create-course-list",
                          attrs: { focusable: "" },
                        },
                        _vm._l(_vm.courses, function (course, i) {
                          return _c(
                            "v-expansion-panel",
                            {
                              key: i,
                              staticClass: "mt-2",
                              staticStyle: {
                                "box-shadow": "none !important",
                                "min-height": "60px",
                              },
                            },
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  staticClass:
                                    "announcement-create-expansion-panel",
                                  staticStyle: { "padding-bottom": "0px" },
                                  attrs: { color: "white" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "margin-announcement-create-course-list",
                                          attrs: { cols: "8" },
                                        },
                                        [_vm._v(_vm._s(course.course_title))]
                                      ),
                                      _c("v-col", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.batches[course.course_id].length
                                          ) + " "
                                        ),
                                        !_vm.$language
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.batches[
                                                      course.course_id
                                                    ].length > 1
                                                      ? "batches"
                                                      : "batch"
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(_vm._s("টি ব্যাচ")),
                                            ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel-content",
                                {
                                  staticStyle: {
                                    "margin-left": "5px",
                                    "margin-top": "16px",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "create-announcement-batch-item",
                                      attrs: { outlined: "" },
                                    },
                                    _vm._l(
                                      _vm.batches[course.course_id],
                                      function (batch, j) {
                                        return _c(
                                          "div",
                                          { key: j },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c("v-checkbox", {
                                                  staticClass:
                                                    "pb-0 mb-0 flex-grow-0",
                                                  attrs: {
                                                    value: batch.batch_id,
                                                  },
                                                  model: {
                                                    value: _vm.selectedBatches,
                                                    callback: function ($$v) {
                                                      _vm.selectedBatches = $$v
                                                    },
                                                    expression:
                                                      "selectedBatches",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "create-announcement-batch-name",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          batch.batch_title
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "1" } }),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  "auto-grow": "",
                                  "clear-icon": "close",
                                  label: !_vm.$language
                                    ? "Announcement Title"
                                    : "নোটিশের শিরোনাম",
                                  placeholder: !_vm.$language
                                    ? "Write your announcement title.."
                                    : "আপনার নোটিশের শিরোনাম লিখুন",
                                },
                                on: {
                                  focus: function ($event) {
                                    _vm.titleFocused = true
                                  },
                                  input: _vm.updateAnnouncementCreateDisabling,
                                },
                                model: {
                                  value: _vm.title,
                                  callback: function ($$v) {
                                    _vm.title =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "title",
                                },
                              }),
                              _vm.titleFocused === true &&
                              (_vm.title.trim().length < 5 ||
                                _vm.title.trim().length > 100)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "quill-validation-message",
                                      staticStyle: { top: "-10%" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "The announcement title must be between 5 and 100 characters."
                                              : "নোটিশের শিরোনাম অবশ্যই ৫ থেকে ১০০ অক্ষরের মধ্যে হতে হবে।"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { position: "relative" },
                              attrs: { cols: "10" },
                            },
                            [
                              _c("quill-editor", {
                                ref: "announcementEditor",
                                staticStyle: { height: "26vh" },
                                attrs: {
                                  options: {
                                    ..._vm.editorOptions,
                                    placeholder:
                                      "Write your announcement message..",
                                    scrollingContainer:
                                      _vm.$refs["announcementEditor"],
                                  },
                                },
                                on: {
                                  focus: function ($event) {
                                    _vm.textFocused = true
                                  },
                                  change: _vm.handleChangeInDescription,
                                },
                                model: {
                                  value: _vm.text,
                                  callback: function ($$v) {
                                    _vm.text =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "text",
                                },
                              }),
                              _c("div", { staticClass: "quill-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language
                                        ? "Announcement Details"
                                        : "নোটিশের বিস্তারিত"
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm.textFocused === true &&
                              (_vm.characterCount < 12 ||
                                _vm.characterCount > 600)
                                ? _c(
                                    "div",
                                    { staticClass: "quill-validation-message" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "The announcement details must be between 12 and 600 characters."
                                              : "নোটিশের বিবরণ অবশ্যই ১২ থেকে ৬০০ অক্ষরের মধ্যে হতে হবে।"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", {
                                    attrs: {
                                      cols: _vm.actionButtonsDummyColumn,
                                    },
                                  }),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "create-announcement-action-buttons",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "red",
                                            width: "100px",
                                            rounded: "",
                                            depressed: "",
                                            outlined: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push(
                                                "/teachers/announcements"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                !_vm.$language
                                                  ? "Cancel"
                                                  : "বাতিল করুন"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-4",
                                          attrs: {
                                            color: "primary",
                                            width: "100px",
                                            depressed: "",
                                            rounded: "",
                                            loading: _vm.creatingAnnouncement,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.createAnnouncement()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                !_vm.$language
                                                  ? "Share"
                                                  : "শেয়ার করুন"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }