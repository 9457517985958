<template>
  <div v-if="!renderNow" id="loading-wrapper">
    <div id="loading-text">LOADING</div>
    <div id="loading-content"></div>
  </div>
  <v-container v-else>
    <div style="font-size: 20px" class="mt-4 mb-6">
      <v-btn
        class="mr-2"
        color="black"
        icon
        large
        @click="
          renderNow = false;
          $router.push('/teachers/announcements');
        "
      >
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <span v-if="!$language"
        >{{ editing ? "Edit" : "Create" }} Announcement</span
      >
      <span v-else> নোটিশ {{ editing ? "এডিট" : "তৈরী" }} করুন</span>
    </div>
    <submission-alert-message
      ref="banner"
      v-model="showBannerCondition"
      :message="bannerMessage"
      class="my-3"
      style="max-width: 70vw"
    ></submission-alert-message>
    <v-card class="create-announcement-card" flat outlined>
      <div class="mt-4 ml-6">
        {{ !$language ? "Course List" : "কোর্স লিস্ট" }}
      </div>
      <div class="ml-4 d-flex align-center" style="height: 40px">
        <v-slide-y-transition>
          <span
            v-show="validating && selectedBatches.length === 0"
            class="decline--text"
            >{{
              $language
                ? "নূন্যতম একটি ব্যাচ বেছে নিন"
                : "Please select atleast one batch"
            }}</span
          >
        </v-slide-y-transition>
      </div>
      <v-row no-gutters>
        <v-col
          style="
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 60vh;
            max-width: 80vw;
          "
          class="pr-8"
        >
          <v-expansion-panels
            focusable
            class="margin-announcement-create-course-list"
          >
            <v-expansion-panel
              v-for="(course, i) in courses"
              :key="i"
              class="mt-2"
              style="box-shadow: none !important; min-height: 60px"
            >
              <v-expansion-panel-header
                class="announcement-create-expansion-panel"
                color="white"
                style="padding-bottom: 0px"
              >
                <v-row>
                  <v-col
                    cols="8"
                    class="margin-announcement-create-course-list"
                    >{{ course.course_title }}</v-col
                  >
                  <v-col
                    >{{ batches[course.course_id].length }}
                    <span v-if="!$language">
                      {{
                        batches[course.course_id].length > 1
                          ? "batches"
                          : "batch"
                      }}
                    </span>
                    <span v-else>{{ "টি ব্যাচ" }}</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                style="margin-left: 5px; margin-top: 16px"
              >
                <v-card outlined class="create-announcement-batch-item">
                  <div v-for="(batch, j) in batches[course.course_id]" :key="j">
                    <v-row no-gutters>
                      <v-checkbox
                        v-model="selectedBatches"
                        class="pb-0 mb-0 flex-grow-0"
                        :value="batch.batch_id"
                      ></v-checkbox>
                      <div class="create-announcement-batch-name">
                        {{ batch.batch_title }}
                      </div>
                    </v-row>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model.trim="title"
                outlined
                auto-grow
                clear-icon="close"
                :label="!$language ? 'Announcement Title' : 'নোটিশের শিরোনাম'"
                :placeholder="
                  !$language
                    ? 'Write your announcement title..'
                    : 'আপনার নোটিশের শিরোনাম লিখুন'
                "
                @focus="titleFocused = true"
                @input="updateAnnouncementCreateDisabling"
              ></v-text-field>
              <div
                v-if="
                  titleFocused === true &&
                  (title.trim().length < 5 || title.trim().length > 100)
                "
                class="quill-validation-message"
                style="top: -10%"
              >
                {{
                  !$language
                    ? "The announcement title must be between 5 and 100 characters."
                    : "নোটিশের শিরোনাম অবশ্যই ৫ থেকে ১০০ অক্ষরের মধ্যে হতে হবে।"
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" style="position: relative">
              <quill-editor
                ref="announcementEditor"
                v-model.trim="text"
                style="height: 26vh"
                :options="{
                  ...editorOptions,
                  placeholder: 'Write your announcement message..',
                  scrollingContainer: $refs['announcementEditor'],
                }"
                @focus="textFocused = true"
                @change="handleChangeInDescription"
              ></quill-editor>
              <div class="quill-label">
                {{ !$language ? "Announcement Details" : "নোটিশের বিস্তারিত" }}
              </div>
              <div
                v-if="
                  textFocused === true &&
                  (characterCount < 12 || characterCount > 600)
                "
                class="quill-validation-message"
              >
                {{
                  !$language
                    ? "The announcement details must be between 12 and 600 characters."
                    : "নোটিশের বিবরণ অবশ্যই ১২ থেকে ৬০০ অক্ষরের মধ্যে হতে হবে।"
                }}
              </div>
              <v-row>
                <v-col :cols="actionButtonsDummyColumn"></v-col>
                <v-col cols="8" class="create-announcement-action-buttons">
                  <v-btn
                    color="red"
                    width="100px"
                    rounded
                    depressed
                    outlined
                    @click="$router.push('/teachers/announcements')"
                  >
                    {{ !$language ? "Cancel" : "বাতিল করুন" }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    width="100px"
                    depressed
                    rounded
                    class="ml-4"
                    :loading="creatingAnnouncement"
                    @click="createAnnouncement()"
                  >
                    {{ !$language ? "Share" : "শেয়ার করুন" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import announcementService from "#ecf/announcement/services/AnnouncementService";
import { getOrgCourses, getOwnCourses } from "#ecf/courses/actions";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";

export default {
  name: "CreateAnnouncements",
  components: { SubmissionAlertMessage },
  data() {
    return {
      showBannerCondition: false,
      announcementCreateDisabled: true,
      validating: false,
      characterCount: 0,
      textFocused: false,
      titleFocused: false,
      courses: [],
      editing: false,
      renderNow: false,
      courseIdForBatch: {},
      courseIdVsPartnerCode: {},
      announcementId: undefined,
      title: "",
      text: "",
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
      selectedBatches: [],
      creatingAnnouncement: false,
    };
  },
  computed: {
    bannerMessage() {
      return this.$language
        ? "সকল প্রয়োজনীয় তথ্য পূরণ করুন"
        : "Please fill out the mandatory fields";
    },
    actionButtonsDummyColumn() {
      if (this.$vuetify.breakpoint.width > 1199) {
        return 4;
      } else {
        return 1;
      }
    },
    editor() {
      return this.$refs.announcementEditor.quill;
    },

    organizations() {
      return this.$store.getters["organization/organizations"];
    },

    batches() {
      return this.$store.getters["batch/batches"];
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.renderNow = true;
      return;
    }
    this.renderNow = false;
    await this.listCourses();
    if (this.$attrs.announcement !== undefined) {
      this.selectedBatches = Object.keys(
        this.$attrs.announcement.shared_with_batches
      ); // batch_ids need filtering per course, to do later
      this.title = this.$attrs.announcement.title;
      this.text = this.$attrs.announcement.text;
      this.announcementId = this.$attrs.announcement.announcement_id;
      this.editing = true;
    }
    this.renderNow = true;
  },
  watch: {
    selectedBatches(value) {
      this.updateAnnouncementCreateDisabling();
    },
    announcementCreateDisabled(value) {
      if (!value) {
        this.validating = false;
        this.titleFocused = false;
        this.textFocused = false;
        this.showBannerCondition = false;
      }
    },
  },
  methods: {
    updateAnnouncementCreateDisabling() {
      this.calculateCharacterCount();
      this.announcementCreateDisabled =
        this.selectedBatches.length === 0 ||
        this.characterCount < 12 ||
        this.characterCount > 600 ||
        this.title.length < 5 ||
        this.title.length > 100;
    },
    handleChangeInDescription() {
      this.calculateCharacterCount();
      this.updateAnnouncementCreateDisabling();
    },
    calculateCharacterCount() {
      const quill = this.editor;
      const text = quill.getText();
      const strippedText = text.trim().replace(/\s+/g, " ");
      this.characterCount = strippedText.length;
    },

    async fetchBatches(courseId, partner_code) {
      try {
        const batches = await this.$store.dispatch("batch/list", {
          courseId,
          partner_code,
        });
        this.$set(this.batches, courseId, batches);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async listCourses() {
      await getOwnCourses(this.$store);
      await getOrgCourses(this.$store);
      this.courses = this.$store.state.courses.myCourses.concat(
        this.$store.state.courses.orgCourses
      );
      for (let i = 0; i < this.courses.length; i++) {
        await this.fetchBatches(
          this.courses[i].course_id,
          this.courses[i].partner_code
        );
      }
    },
    async createAnnouncement() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      if (this.creatingAnnouncement) return;
      if (this.announcementCreateDisabled) {
        if (!this.validating) {
          this.validating = true;
          this.titleFocused = true;
          this.textFocused = true;
        }
        if (!this.showBannerCondition) this.showBannerCondition = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }
      for (let i in this.batches) {
        for (let j = 0; j < this.batches[i].length; j++)
          this.courseIdForBatch[this.batches[i][j]["batch_id"]] =
            this.batches[i][j].course_id;
      }
      let course_ids = {};
      for (let i = 0; i < this.selectedBatches.length; i++) {
        let batch_obj =
          this.batches[this.courseIdForBatch[this.selectedBatches[i]]][0];
        if (!(batch_obj["course_id"] in course_ids)) {
          course_ids[batch_obj["course_id"]] = [batch_obj["batch_id"]];
          this.courseIdVsPartnerCode[batch_obj["course_id"]] =
            batch_obj["partner_code"];
        } else course_ids[batch_obj["course_id"]].push(batch_obj["batch_id"]);
      }
      for (let course_id in course_ids) {
        try {
          this.creatingAnnouncement = true;
          this.announcements = await announcementService.save(
            course_id,
            this.selectedBatches, // batch_ids need filtering per course, to do later
            this.$store.state.user.user.user_id,
            this.title,
            this.text,
            this.announcementId,
            this.courseIdVsPartnerCode[course_id]
          );
        } catch (e) {
          this.$root.$emit("alert", [undefined, e.message]);
        } finally {
          this.creatingAnnouncement = false;
        }
      }
      await this.$router.push("/teachers/announcements");
    },
  },
};
</script>
<style>
.v-expansion-panel-header::before {
  background-color: white !important;
}
</style>

<style scoped lang="scss">
.create-announcement-card {
  height: 80%;
  width: 70vw;
  margin-top: 16px;
  padding-left: 32px;
}

@media (max-width: 1199px) {
  .create-announcement-card {
    height: 100%;
    width: 70vw;
    margin-top: 16px;
    padding-left: 32px;
  }
}

.quill-label {
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: white;
  padding: 4px 6px 0px;
  font-size: 0.7rem;
  color: #787878;
}

.announcement-title-validation-message {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 104px;
}

@media (min-width: 300px) and (max-width: 799px) {
  .announcement-title-validation-message {
    top: 238px;
  }
}

@media (min-width: 800px) and (max-width: 1199px) {
  .announcement-title-validation-message {
    top: 108px;
  }
}

.quill-validation-message {
  color: red;
  font-size: 12px;
  position: relative;
  top: 50px;
}

@media (max-width: 1199px) {
  .quill-validation-message {
    color: red;
    font-size: 12px;
    position: relative;
    top: 80px;
  }
}

.margin-announcement-create-course-list {
  margin-left: 18px;
  width: 60vw;
}

@media (max-width: 1199px) {
  .margin-announcement-create-course-list {
    margin-left: 2px;
    max-width: 20ch;
    text-orientation: sideways;
  }
}

.create-announcement-batch-item {
  padding-top: 12px;
  padding-left: 32px;
  padding-right: 32px;
}
.create-announcement-action-buttons {
  margin-top: 80px;
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: end;
}
.create-announcement-batch-name {
  padding-top: 10px;
  margin-top: 14px;
  margin-left: 0px;
  font-size: 12px;
}

@media screen and (max-width: 1199px) {
  .create-announcement-batch-item {
    padding-top: 10px;
    padding-left: 32px;
    padding-right: 0px;
  }

  .create-announcement-action-buttons {
    margin-top: 60px;
    margin-left: 10px;
    height: 12vh;
    display: flex;
    align-items: end;
    justify-content: center;
  }

  .announcement-create-expansion-panel {
    font-size: 12px;
  }
}
</style>
